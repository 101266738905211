import React, { ReactNode, useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { Roles } from "../models/userData";
import { useAuth } from "../hooks/useAuth";

/**
 * Props for the SocketContext.
 */
export type SocketContextProps = {
    lastMessage: MessageEvent | null;
    isOpen: boolean;
};

/**
 * Context for managing WebSocket connection and messages.
 */
export const SocketContext = React.createContext<SocketContextProps | undefined>(undefined);

/**
 * Builds the WebSocket URL based on the current document location.
 * @returns The WebSocket URL.
 */
function buildSocketUrl(): string {
    const { protocol, host } = document.location;
    const isHttps = protocol === "https:";
    const socketProtocol = isHttps ? "wss" : "ws";
    const socketUrl = `${socketProtocol}://${host}/ws`;
    return socketUrl;
}

/**
 * Builds the message to be sent when the WebSocket connection is opened.
 * @param userRoles - The roles of the user.
 * @returns The session message.
 */
function buildOnOpenSessionMessage(userRoles: string[], token: string): string {
    const appCode = userRoles.includes(Roles.LOCKS_AND_DOCKS_DISPATCHER) ? "dock" : "disp";
    return `session:${appCode}:Bearer ${token}`;
}

const sent = new WeakMap();
/**
 * Provider component for the SocketContext.
 * Manages the WebSocket connection and provides the last received message.
 * @param children - The child components.
 * @returns The SocketProvider component.
 */
export function SocketProvider({ children }: { children: ReactNode }) {
    const { user } = useAuth();
    const userRoles = user.roles;
    const token = user.token;
    const socketUrl = buildSocketUrl();
    const { sendMessage, lastMessage, getWebSocket, readyState } = useWebSocket(socketUrl, {
        reconnectInterval: 10000,
    });
    const isOpen = readyState === ReadyState.OPEN;

    useEffect(() => {
        if (!token) return;
        const ws = getWebSocket();
        if (!ws || sent.has(ws)) return;
        sendMessage(buildOnOpenSessionMessage(userRoles, token));
        sent.set(ws, true);
    }, [userRoles, sendMessage, token, getWebSocket]);

    return (
        <SocketContext.Provider value={{ lastMessage, isOpen }}>{children}</SocketContext.Provider>
    );
}
