import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import Messages from "../../../../localization/Messages";
import { showBackendMessage } from "../../../../helpers/messagesHelper";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Autocomplete from "@mui/material/Autocomplete";
import { IDock } from "../../../../models/dock";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import {
    IAddEditInternalBooking,
    IInternalBooking,
    Type,
    internalBookingInit,
} from "../../../../models/internalBooking";
import { addInternalBooking, changeInternalBooking } from "../../../../api/internalBooking";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    Grid,
} from "@mui/material";
import { DateFormat, getDateMoment } from "../../../../helpers/dateHelper";
import { AppState } from "../../../../store/configureStore";
import { useCachedDocks } from "../../../../hooks/useCachedDocks";
import "./index.css";

type InternalBookingErrors = {
    [Key in keyof Partial<IInternalBooking>]: string;
};

const validationRegex = {
    eni: /^\d{8}$/,
};

export interface IDockMenu {
    dockId: string | undefined;
    label: string;
    loading: boolean;
    setDockId: React.Dispatch<React.SetStateAction<string>>;
    booking: IAddEditInternalBooking;
    validate: () => InternalBookingErrors;
    errors: InternalBookingErrors;
}

const DockMenu = ({ dockId, label, loading, setDockId, errors }: IDockMenu) => {
    const { docks, byId } = useCachedDocks();
    return (
        <Autocomplete
            id="dock-autocomplete-respond-form"
            options={docks}
            getOptionLabel={o => o.name}
            isOptionEqualToValue={(a, b) => a.id === b.id}
            renderInput={params => (
                <TextField
                    className="internal-bookings-wide-dropdown-field"
                    variant="filled"
                    {...params}
                    label={label}
                    required
                    error={Boolean(errors.dockId)}
                    helperText={errors.dockId}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    <div>{option.name}</div>
                </li>
            )}
            onChange={(_, value: IDock | null) => {
                setDockId(value?.id ?? "");
            }}
            value={byId.get(dockId ?? "") ?? null}
            disabled={loading}
        />
    );
};

export interface IInternalBookingForm {
    selectedBooking: IAddEditInternalBooking;
    internalEditMode: boolean;
    cancelBookingCreating: () => void;
    handleDeleteInternalBooking: (bookingGuid: string) => void;
    getBookings: () => Promise<void>;
}

/**
 * Represents the internal booking form used for creating dispatcher's internal bookings.
 * This component is rendered in the sidebar of the Dock Allocation plan or Manage Internal bookings page.
 */
export const InternalBookingForm = ({
    selectedBooking,
    internalEditMode,
    cancelBookingCreating,
    handleDeleteInternalBooking,
    getBookings,
}: IInternalBookingForm) => {
    const timeZones = useSelector((s: AppState) => s.destinationZones).data;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [tab, setTab] = useState(
        internalEditMode ? (selectedBooking.type == Type.BOOKING ? 0 : 1) : 0,
    );
    const [showInternalDeleteDialog, setShowInternalDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<InternalBookingErrors>({});
    const [newBooking, setNewBooking] = useState<IAddEditInternalBooking>(internalBookingInit);
    const [arrivalTime, setArrivalTime] = useState<Date>(
        getDateMoment(selectedBooking.arrivalTime).toDate(),
    );
    const [departureTime, setDepartureTime] = useState<Date>(
        getDateMoment(selectedBooking.departureTime).toDate(),
    );
    const [dockId, setDockId] = useState<string>(selectedBooking.dockId);
    const [isPublic, setIsPublic] = useState<boolean>(selectedBooking.public);

    /*useEffect(() => {
        setType(selectedBooking.type);
        setArrivalTime(getDateMoment(selectedBooking.arrivalTime).toDate());
        setDepartureTime(getDateMoment(selectedBooking.departureTime).toDate());
        setDockId(selectedBooking.dockId);
        setNewBooking(selectedBooking);
        setTab(internalEditMode ? (selectedBooking.type == Type.BOOKING ? 0 : 1) : 0);
    }, [selectedBooking, internalEditMode]);

    useEffect(() => {
        setNewBooking(
            selectedBooking
                ? {
                      ...selectedBooking,
                      public: isPublic,
                      type: type,
                      dockId: dockId,
                      arrivalTime: arrivalTime.getTime(),
                      departureTime: departureTime.getTime(),
                  }
                : selectedBooking,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBooking]);

    useEffect(() => {
        setIsPublic(selectedBooking.public);
    }, [selectedBooking, internalEditMode]);*/

    useEffect(() => {
        setNewBooking({
            ...selectedBooking,
            dockId,
            arrivalTime: arrivalTime.getTime(),
            departureTime: departureTime.getTime(),
        });
        setTab(selectedBooking.type === Type.BOOKING ? 0 : 1);
    }, [selectedBooking, dockId, arrivalTime, departureTime]);

    const onChange = (diff: Partial<IInternalBooking>) => {
        setNewBooking({ ...newBooking, ...diff });
        const clearErros = Object.fromEntries(Object.entries(diff).map(([k]) => [k, undefined]));
        setErrors({ ...errors, ...clearErros });
    };

    const validateBooking = () => {
        const newErrors: InternalBookingErrors = {};
        if (!newBooking.arrivalTime) {
            newErrors.arrivalTime = intl.formatMessage(Messages.arrivalTimeIsRequired);
        }
        if (!newBooking.departureTime) {
            newErrors.departureTime = intl.formatMessage(Messages.departureTimeIsRequired);
        }
        if (isNaN(newBooking.arrivalTime) || isNaN(arrivalTime.getTime())) {
            newErrors.arrivalTime = intl.formatMessage(Messages.wrongDateFormat);
        }
        if (isNaN(newBooking.departureTime) || isNaN(departureTime.getTime())) {
            newErrors.departureTime = intl.formatMessage(Messages.wrongDateFormat);
        }
        if (!newBooking.dockId) {
            newErrors.dockId = intl.formatMessage(Messages.dockIsRequired);
        }
        if (newBooking.type == Type.BOOKING && !newBooking.shipName) {
            newErrors.shipName = intl.formatMessage(Messages.shipNameIsRequired);
        }
        if (newBooking.eni && !validationRegex.eni.test(newBooking.eni))
            newErrors.eni = intl.formatMessage(Messages.eniIsIncorrect);
        return newErrors;
    };

    const handleSubmit = async () => {
        const newErrors = validateBooking();
        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
        } else {
            setLoading(true);
            try {
                if (newBooking.type === Type.BOOKING) {
                    newBooking.reason = "";
                    newBooking.public = true;
                } else if (newBooking.type === Type.BLOCKING) {
                    newBooking.shipName = "";
                    newBooking.eni = "";
                    newBooking.public = isPublic;
                }

                const timeZone = timeZones.get(selectedBooking.destinationId);
                const tzValue = { timeZone };
                const arrival = getDateMoment(arrivalTime, tzValue).valueOf();
                const departure = getDateMoment(departureTime, tzValue).valueOf();

                const newSanitizedBooking = {
                    ...newBooking,
                    arrivalTime: arrival,
                    departureTime: departure,
                };
                await (internalEditMode
                    ? changeInternalBooking(newSanitizedBooking)
                    : addInternalBooking(newSanitizedBooking));
                dispatch(
                    showBackendMessage(
                        intl,
                        "success",
                        internalEditMode ? "updating" : "creating",
                        Messages.internalBooking,
                    ),
                );
            } catch {
                dispatch(
                    showBackendMessage(
                        intl,
                        "error",
                        internalEditMode ? "updating" : "creating",
                        Messages.internalBooking,
                    ),
                );
            } finally {
                setLoading(false);
                getBookings();
            }
        }
    };

    return (
        <>
            <div className="booking-respond-form-container">
                <Box marginBottom={2}>
                    <Tabs
                        value={tab}
                        onChange={(_event: React.SyntheticEvent, value: number) => {
                            setTab(value);
                            internalEditMode ?? setNewBooking(internalBookingInit);
                            setNewBooking(newBooking => {
                                const updatedBooking = {
                                    ...newBooking,
                                    arrivalTime: (arrivalTime as Date).getTime(),
                                    departureTime: (departureTime as Date).getTime(),
                                    dockId: selectedBooking.dockId,
                                    type: value ? Type.BLOCKING : Type.BOOKING,
                                };

                                if (internalEditMode) {
                                    return {
                                        ...selectedBooking,
                                        ...updatedBooking,
                                    };
                                } else {
                                    return updatedBooking;
                                }
                            });
                        }}
                        indicatorColor="primary"
                        textColor="inherit"
                        variant="fullWidth"
                    >
                        <Tab label={intl.formatMessage(Messages.booking)} />
                        <Tab label={intl.formatMessage(Messages.blocking)} />
                    </Tabs>
                </Box>
                {tab === 0 && (
                    <Box margin={1}>
                        <Grid spacing={2} container>
                            <Grid item xs={6}>
                                <TextField
                                    className="internal-bookings-text-field"
                                    required
                                    error={Boolean(errors.shipName)}
                                    value={newBooking.shipName}
                                    label={intl.formatMessage(Messages.shipName)}
                                    onChange={e => onChange({ shipName: e.target.value })}
                                    variant="filled"
                                    helperText={errors.shipName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    error={Boolean(errors.eni)}
                                    helperText={errors.eni}
                                    className="internal-bookings-text-field"
                                    value={newBooking.eni ?? ""}
                                    label={intl.formatMessage(Messages.eni)}
                                    onChange={e => onChange({ eni: e.target.value })}
                                    variant="filled"
                                    inputProps={{
                                        inputMode: "numeric",
                                        maxLength: 8,
                                        pattern: "[0-8]*",
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DesktopDateTimePicker
                                    label={intl.formatMessage(Messages.arrivalTime)}
                                    inputFormat={DateFormat.CLIENT_DATE_TIME}
                                    value={arrivalTime}
                                    onChange={value =>
                                        setArrivalTime(
                                            value
                                                ? getDateMoment(value).toDate()
                                                : getDateMoment().toDate(),
                                        )
                                    }
                                    renderInput={params => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                            required
                                            error={Boolean(errors.arrivalTime)}
                                            helperText={errors.arrivalTime}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DesktopDateTimePicker
                                    label={intl.formatMessage(Messages.departureTime)}
                                    inputFormat={DateFormat.CLIENT_DATE_TIME}
                                    value={departureTime}
                                    onChange={value =>
                                        setDepartureTime(
                                            value
                                                ? getDateMoment(value).toDate()
                                                : getDateMoment().toDate(),
                                        )
                                    }
                                    renderInput={params => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                            required
                                            error={Boolean(errors.departureTime)}
                                            helperText={errors.departureTime}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <DockMenu
                                    dockId={dockId}
                                    label={intl.formatMessage(Messages.dock)}
                                    loading={loading}
                                    setDockId={setDockId}
                                    booking={newBooking}
                                    validate={validateBooking}
                                    errors={errors}
                                />
                            </Grid>
                        </Grid>
                        <Box className="tab-actions-container">
                            <Button
                                variant="contained"
                                className="submit-vessel-form-button button-primary"
                                onClick={handleSubmit}
                            >
                                {intl.formatMessage(Messages.save)}
                            </Button>
                            <Button
                                variant="outlined"
                                className="submit-vessel-form-button button-primary"
                                onClick={cancelBookingCreating}
                            >
                                {intl.formatMessage(Messages.cancel)}
                            </Button>
                            {internalEditMode && (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    className="submit-vessel-form-button button-primary"
                                    onClick={() => setShowInternalDeleteDialog(true)}
                                >
                                    {intl.formatMessage(Messages.delete)}
                                </Button>
                            )}
                        </Box>
                    </Box>
                )}
                {tab === 1 && (
                    <Box margin={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DockMenu
                                    dockId={dockId}
                                    label={intl.formatMessage(Messages.dock)}
                                    loading={loading}
                                    setDockId={setDockId}
                                    booking={newBooking}
                                    validate={validateBooking}
                                    errors={errors}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DesktopDateTimePicker
                                    label={intl.formatMessage(Messages.startDate)}
                                    inputFormat={DateFormat.CLIENT_DATE_TIME}
                                    value={arrivalTime}
                                    onChange={value =>
                                        setArrivalTime(
                                            value
                                                ? getDateMoment(value).toDate()
                                                : getDateMoment().toDate(),
                                        )
                                    }
                                    renderInput={params => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                            required
                                            error={Boolean(errors.arrivalTime)}
                                            helperText={errors.arrivalTime}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <DesktopDateTimePicker
                                    label={intl.formatMessage(Messages.endDate)}
                                    inputFormat={DateFormat.CLIENT_DATE_TIME}
                                    value={departureTime}
                                    onChange={value =>
                                        setDepartureTime(
                                            value
                                                ? getDateMoment(value).toDate()
                                                : getDateMoment().toDate(),
                                        )
                                    }
                                    renderInput={params => (
                                        <TextField
                                            variant="filled"
                                            {...params}
                                            required
                                            error={Boolean(errors.departureTime)}
                                            helperText={errors.departureTime}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={newBooking.reason}
                                    className="internal-bookings-wide-text-field"
                                    label={intl.formatMessage(Messages.reason)}
                                    onChange={e => onChange({ reason: e.target.value })}
                                    variant="filled"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!isPublic}
                                            onChange={() => setIsPublic(prev => !prev)}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    }
                                    label={intl.formatMessage(Messages.doNotShowInPublicPlan)}
                                />
                            </Grid>
                        </Grid>
                        <div className="tab-actions-container">
                            <Button
                                variant="contained"
                                className="submit-vessel-form-button button-primary"
                                onClick={handleSubmit}
                            >
                                {intl.formatMessage(Messages.save)}
                            </Button>
                            <Button
                                variant="outlined"
                                className="submit-vessel-form-button button-primary"
                                onClick={cancelBookingCreating}
                            >
                                {intl.formatMessage(Messages.cancel)}
                            </Button>
                            {internalEditMode && (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    className="submit-vessel-form-button button-primary"
                                    onClick={() => setShowInternalDeleteDialog(true)}
                                >
                                    {intl.formatMessage(Messages.delete)}
                                </Button>
                            )}
                        </div>
                    </Box>
                )}
            </div>

            <Dialog
                open={showInternalDeleteDialog}
                onClose={() => setShowInternalDeleteDialog(false)}
            >
                <DialogTitle>
                    {`${intl.formatMessage(
                        Messages.internalDeleteConfirmation,
                    )} ${selectedBooking.type.toLowerCase()}?`}
                    <div className="delete-dialog-subtitle">
                        {selectedBooking.type === Type.BOOKING
                            ? `${selectedBooking.shipName}${
                                  selectedBooking.eni ? ` (${selectedBooking.eni})` : ""
                              }`
                            : selectedBooking.reason || intl.formatMessage(Messages.blocking)}
                    </div>
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            handleDeleteInternalBooking(newBooking.id);
                        }}
                        color="error"
                    >
                        {intl.formatMessage(Messages.delete)}
                    </Button>
                    <Button
                        onClick={() => {
                            setShowInternalDeleteDialog(false);
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InternalBookingForm;
