import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Messages from "../../../../localization/Messages";
import { showBackendMessage } from "../../../../helpers/messagesHelper";
import { deleteExpense, searchExpense } from "../../../../api/expense";
import { searchParamsInit } from "../../../../models/searchParams";
import { ExpenseStatus, IExpense } from "../../../../models/expense";
import { ExpenseCategory, IExpenseType } from "../../../../models/expenseType";
import { getExpenseType, searchExpenseType } from "../../../../api/expenseType";
import { getDateText } from "../../../../helpers/dateHelper";
import DragMoveComponent from "../../../../components/DragMoveComponent";
import {
    Box,
    Button,
    Tooltip,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";

import {
    HelpOutline,
    DirectionsBoat,
    FlashOn,
    Water,
    Autorenew,
    Build,
    AdminPanelSettings,
} from "@mui/icons-material";
import ExpenseForm from "../../../ManageExpenses/ExpenseForm";
import RpisDialog from "../../../RpisDialog/RpisDialog";
import { displayAmount } from "../../../../helpers/moneyHelper";
import { getInvoicesSearchParams } from "../../../OnCallContainers/OCManageExpenses/shared/constants";
import { useFetchInvoices } from "../../../../hooks/useFetchInvoices";
import { useAuth } from "../../../../hooks/useAuth";
import { Roles } from "../../../../models/userData";
import { AppState } from "../../../../store/configureStore";
import { IService } from "../../../../models/service";
import { getService } from "../../../../api/service";
import { ServiceForm } from "../../../DispManageServices/components/ServiceForm/ServiceForm";
import { buildInvoicesSearchPayload } from "../../shared/filters";

const getIconForCategory = (category: ExpenseCategory) => {
    switch (category) {
        case ExpenseCategory.UNKNOWN:
            return <HelpOutline color="disabled" />;
        case ExpenseCategory.BERTHING_FEES:
            return <DirectionsBoat color="primary" />;
        case ExpenseCategory.ELECTRICITY:
            return <FlashOn color="secondary" />;
        case ExpenseCategory.WATER:
            return <Water color="primary" />;
        case ExpenseCategory.RECYCLING:
            return <Autorenew color="success" />;
        case ExpenseCategory.SERVICES:
            return <Build color="warning" />;
        case ExpenseCategory.ADMINISTRATION:
            return <AdminPanelSettings color="info" />;
        default:
            return <HelpOutline color="disabled" />;
    }
};

export type ExpensesInputProps = {
    invoiceId: number;
    onChange?: (expenses: IExpense[]) => void;
    disabledAddTooltip?: string;
    onExpenseSubmit?: () => void;
};

function getPriceData(expense: IExpense) {
    const localQuantity = expense.quantity ?? 0;
    const localPriceNetPerUnit = expense.netPricePerUnit ?? 0;
    const localVat = expense.vat ?? 0;
    const totalCostNet = localPriceNetPerUnit * localQuantity;
    const totalCostGross = ((1 + localVat) / 100) * totalCostNet;
    return { totalCostNet, totalCostGross };
}

/**
 * Renders an individual expense item in the ExpensesInput component.
 * @component
 * @example
 * return (
 *   <ExpenseItem
 *     expense={expense}
 *     index={0}
 *     onClick={() => console.log("Expense clicked")}
 *     disabled={false}
 *   />
 * )
 */
function ExpenseItem({
    expense,
    index,
    onClick = () => {},
    disabled = false,
}: {
    expense: IExpense;
    index: number;
    onClick?: () => void;
    disabled?: boolean;
}) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const expenseTypeId = expense.expenseTypeId;
    const [expenseType, setExpenseType] = useState<IExpenseType>();
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const fetchExpenseType = async () => {
            try {
                const response = await getExpenseType(expenseTypeId);
                const expenseType = response.data;
                setExpenseType(expenseType);
            } catch (err) {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.expenseType));
            }
        };

        if (expenseTypeId) {
            fetchExpenseType();
        }
    }, [expenseTypeId, dispatch, intl]);

    const quantity = displayAmount(expense.quantity!);
    const currency = expense.currency!;
    const unit = expense.unit!;
    const priceNetPerUnit = expense.netPricePerUnit!;
    const { totalCostNet, totalCostGross } = getPriceData(expense);

    const category = expenseType?.category as ExpenseCategory;
    const expenseDate = getDateText(expense.expenseDate);
    const name = expenseType?.name;
    const expenseDetailsRow1 = `${name} | ${expenseDate}`;
    const expenseDetailsRow2 = `${quantity}${unit} @ ${displayAmount(
        priceNetPerUnit,
        currency,
    )}/${unit} | VAT ${displayAmount(totalCostGross, currency)} | Net ${displayAmount(
        totalCostNet,
        currency,
    )}`;

    return (
        <Tooltip
            title={intl.formatMessage(Messages.expenseNotOpenOrUnassigned)}
            open={showTooltip}
            onClose={() => setShowTooltip(false)}
        >
            <Box
                alignItems="center"
                display="flex"
                gap={1}
                onClick={() => {
                    if (disabled) {
                        setShowTooltip(true);
                        return;
                    }
                    onClick();
                }}
            >
                <Typography variant="body2">{index + 1}</Typography>
                <Box display="flex" alignItems="center">
                    {getIconForCategory(category)}
                </Box>
                <Typography variant="body2">
                    {expenseDetailsRow1}
                    <br />
                    {expenseDetailsRow2}
                </Typography>
            </Box>
        </Tooltip>
    );
}

/**
 * Renders the Expenses input section of Manage Invoices sidebar.
 *
 * This component is used for adding new expenses or managing existing ones for the selected invoice.
 * It also utilizes the DragMoveComponent to allow the user to change the order of expenses.
 *
 * @component
 * @example
 * return (
 *   <ExpensesInput
 *     invoiceId={123}
 *     onChange={(expenses) => console.log(expenses)}
 *     disabledAddTooltip="Add Expense is disabled"
 *     onExpenseSubmit={() => console.log("Expense submitted")}
 *   />
 * )
 */
export default function ExpensesInput({
    disabledAddTooltip = "",
    invoiceId,
    onChange,
    onExpenseSubmit,
}: ExpensesInputProps) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [expenses, setExpenses] = useState<IExpense[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedService, setSelectedService] = useState<IService>();
    const [selectedExpense, setSelectedExpense] = useState<IExpense>();
    const [expenseTypes, setExpenseTypes] = useState<IExpenseType[]>([]);
    const { data } = useFetchInvoices({
        payload: buildInvoicesSearchPayload({
            ...getInvoicesSearchParams(invoiceId),
            internalInvoiceNumber: "",
            dateFrom: null,
            dateTo: null,
            shipId: null,
            bookingDateFrom: null,
            bookingDateTo: null,
            invoiceStatus: [],
            invoiceDate: null,
            order: [],
            rowsPerPage: 100,
            page: 0,
        }),
    });
    const invoices = useMemo(() => data?.invoices ?? [], [data]);
    const disabled = expenses.length === 0;
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [toDeleteId, setToDeleteId] = useState("");
    const { hasRole } = useAuth();
    const isOnCall = hasRole(Roles.ON_CALL_DISPATCHER);
    const serverSettings = useSelector((s: AppState) => s.serverSettings.data);
    const isFromBasel = serverSettings.userFromBasel;

    useEffect(() => {
        const fetchService = async () => {
            try {
                const selectedServiceId = selectedExpense?.serviceId;
                if (!selectedServiceId) return;
                const res = await getService(selectedServiceId);
                setSelectedService(res.data);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.services));
            }
        };
        fetchService();
    }, [selectedExpense, dispatch, intl]);

    useEffect(() => {
        const getExpenseTypes = async () => {
            try {
                const res = await searchExpenseType({
                    ...searchParamsInit,
                    filter: { $and: [{ custom: false }] },
                });
                setExpenseTypes(res.data.rows ?? []);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.expenseTypes));
            }
        };
        getExpenseTypes();
    }, [dispatch, intl]);

    const loadExpenses = useCallback(() => {
        const fetchExpenses = async () => {
            try {
                const response = await searchExpense({
                    ...searchParamsInit,
                    filter: { $and: [{ invoiceId: invoiceId }] },
                });
                const expenses = response.data.rows;
                setExpenses(expenses.sort((a: IExpense, b: IExpense) => a.ordnum - b.ordnum));
            } catch (err) {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.expenses));
            }
        };

        if (invoiceId) {
            fetchExpenses();
        } else {
            setExpenses([]);
        }
    }, [invoiceId, dispatch, intl]);

    useEffect(() => {
        loadExpenses();
    }, [loadExpenses]);

    const onExpenseCreateClick = () => {
        setSelectedExpense(undefined);
        setDialogOpen(true);
    };

    const onExpenseRowClick = (expense: IExpense) => {
        if (disabled) return;
        setSelectedExpense(expense);
        setDialogOpen(true);
    };
    const handleDeleteExpenseClick = (id: string) => {
        setToDeleteId(id);
        setDeleteDialog(true);
    };

    const confirmDeleteExpense = useCallback(
        async (expenseId: string) => {
            try {
                await deleteExpense(expenseId);
                dispatch(showBackendMessage(intl, "success", "deleting", Messages.expense));
                loadExpenses();
            } catch {
                dispatch(showBackendMessage(intl, "error", "deleting", Messages.expense));
            }
        },
        [dispatch, loadExpenses, intl],
    );

    const { totalCostNetSum, totalCostGrossSum } = expenses.reduce(
        (acc, expense) => {
            const { totalCostNet, totalCostGross } = getPriceData(expense);
            const totalCostNetSum = acc.totalCostNetSum + totalCostNet;
            const totalCostGrossSum = acc.totalCostGrossSum + totalCostGross;
            return { totalCostNetSum, totalCostGrossSum };
        },
        { totalCostNetSum: 0, totalCostGrossSum: 0 },
    );

    const closeDialogAndReloadExpenses = () => {
        setDialogOpen(false);
        loadExpenses();
    };

    return (
        <>
            <Box>
                <DragMoveComponent
                    disabled={disabled}
                    items={expenses}
                    title={
                        <Box display="flex" alignItems="center" gap={2}>
                            <Typography>{intl.formatMessage(Messages.expenses)}</Typography>
                            {!(isOnCall && isFromBasel) && (
                                <Tooltip title={disabledAddTooltip}>
                                    <span>
                                        <Button
                                            disabled={!!disabledAddTooltip}
                                            variant="contained"
                                            size="small"
                                            onClick={onExpenseCreateClick}
                                        >
                                            {intl.formatMessage(Messages.addExpense)}
                                        </Button>
                                    </span>
                                </Tooltip>
                            )}
                        </Box>
                    }
                    onChange={e => {
                        setExpenses(e);
                        onChange?.(e);
                    }}
                    keyMapper={({ id }) => id}
                    rowMapper={(expense, index) => (
                        <ExpenseItem
                            onClick={() => {
                                if (!(isOnCall && isFromBasel)) {
                                    onExpenseRowClick(expense);
                                }
                            }}
                            expense={expense}
                            index={index}
                            disabled={
                                ![ExpenseStatus.NOT_ASSIGNED, ExpenseStatus.OPEN].includes(
                                    expense.status!,
                                ) ||
                                (expense.category === "SERVICES" &&
                                    !!invoices.find(e => e.id === invoiceId)?.bookingFinalized)
                            }
                        />
                    )}
                />
                {!disabled && !isOnCall && (
                    <Box display="flex" flexDirection="column" gap={1}>
                        <span>
                            {intl.formatMessage(Messages.totalCostNet)}:{" "}
                            {displayAmount(totalCostNetSum, expenses[0].currency)}
                        </span>
                        <span>VAT: {displayAmount(totalCostGrossSum, expenses[0].currency)}</span>
                        <span>
                            {intl.formatMessage(Messages.totalCostGross)}:{" "}
                            {displayAmount(
                                totalCostGrossSum + totalCostNetSum,
                                expenses[0].currency,
                            )}
                        </span>
                    </Box>
                )}
                <RpisDialog
                    fullWidth
                    className="dialog-form"
                    title={intl.formatMessage(
                        selectedExpense?.id ? Messages.editExpense : Messages.createExpense,
                    )}
                    dialogOpen={dialogOpen}
                    size="md"
                    onClose={() => setDialogOpen(false)}
                    content={
                        selectedExpense?.category === "SERVICES" ? (
                            selectedService ? (
                                <ServiceForm
                                    bookingId={selectedService.bookingId!}
                                    selectedService={selectedService}
                                    onClose={() => setDialogOpen(false)}
                                    onDelete={closeDialogAndReloadExpenses}
                                    onAddService={closeDialogAndReloadExpenses}
                                />
                            ) : (
                                <></>
                            )
                        ) : (
                            <ExpenseForm
                                expenseTypes={expenseTypes}
                                invoices={invoices}
                                selectedExpense={selectedExpense}
                                callback={() => {
                                    loadExpenses();
                                    onExpenseSubmit && onExpenseSubmit();
                                }}
                                showBookingDetails={false}
                                showTitle={false}
                                handleCloseSidebar={() => setDialogOpen(false)}
                                invoiceId={invoiceId}
                                showDeleteButton={true}
                                onDeleteExpense={id => handleDeleteExpenseClick(id)}
                            />
                        )
                    }
                />
            </Box>

            <Dialog
                open={deleteDialog}
                className="expense-actions-dialog"
                onClose={() => {
                    setDeleteDialog(false);
                }}
            >
                <DialogTitle>{intl.formatMessage(Messages.deleteExpense)}</DialogTitle>
                <DialogContent>
                    <span>{intl.formatMessage(Messages.deleteExpenseMessage)}</span>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDeleteDialog(false);
                            setToDeleteId("");
                        }}
                    >
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            confirmDeleteExpense(toDeleteId);
                            setDeleteDialog(false);
                            setToDeleteId("");
                            setDialogOpen(false);
                        }}
                    >
                        {intl.formatMessage(Messages.confirm)}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
