import { Button, Dialog, DialogActions, DialogTitle, Menu, MenuItem, Tooltip } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import RpisPage from "../RpisPage/RpisPage";
import { useDispatch, useSelector } from "react-redux";
import Messages from "../../localization/Messages";
import { IVoyage } from "../../models/voyage";
import { deleteVoyage, getVoyage } from "../../api/voyage";
import { showBackendMessage } from "../../helpers/messagesHelper";
import { useNavigate, useParams } from "react-router-dom";
import { searchShip } from "../../api/ship";
import { IShip } from "../../models/ship";
import Divider from "@mui/material/Divider";
import StopForm from "./StopForm";
import { ISearchParams, searchParamsInit } from "../../models/searchParams";
import { getStop, searchStops } from "../../api/stop";
import { IAddEditStop, IStop } from "../../models/stop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VoyageForm from "./VoyageForm";
import CircularProgress from "@mui/material/CircularProgress";
import AnchorIcon from "@mui/icons-material/Anchor";
import IconButton from "@mui/material/IconButton";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import Chat from "../../components/Chat/Chat";
import classNames from "classnames";
import { useUrlState } from "../../hooks/useUrlState";
import { DateFormat, getDateMoment, getDateText } from "../../helpers/dateHelper";
import { AppState } from "../../store/configureStore";
import { getStatusChip } from "../../components/StatusChip/StatusChip";
import BerthingsDockName from "./components/BerthingsDockName";
import { useFetchDestinations } from "../../hooks/useFetchDestinations";
import { useFetchAddresses } from "../../hooks/useFetchAddresses";
import "./LDManageVoyages.css";
import "./PlanVoyage.css";
import { useFetchChangeVoyageAvailable } from "./hooks/useFetchChangeVoyageAvailable";

/**
 * Renders the Plan Voyage page of Locks & Docks.
 *
 * This page allows the user to create or edit a particular voyage.
 * The user can add, edit, or delete stops and send booking requests.
 * This page displays existing stops in a timeline format.
 * The page also features a chat component where the user can communicate with a dispatcher.
 */
export const PlanVoyage = () => {
    const { guid } = useParams();
    const intl = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const destinations = useFetchDestinations();
    const [voyage, setVoyage] = useState<IVoyage>();
    const [loading, setLoading] = useState(false);
    const [showStop, setShowStop] = useState(false);
    const [selectedStopId, setSelectedStopId] = useUrlState<number | null>("selectedStopId", null);
    const [stops, setStops] = useState<IStop[]>([]);
    const addresses = useFetchAddresses();
    const [ships, setShips] = useState<IShip[]>([]);
    const [disableShipInput, setDisableShipInput] = useState<boolean>(false);
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
    const [hasActiveStatus, setHasActiveStatus] = useState<boolean>(false);
    const [hasBookingRequests, setHasBookingRequests] = useState<boolean>(false);
    const [showDiscardVoyageModal, setShowDiscardVoyageModal] = useState(false);
    const timeZones = useSelector((s: AppState) => s.destinationZones).data;

    const { data: changeVoyageAvailable } = useFetchChangeVoyageAvailable(Number(guid));

    const handleSelectStop = useCallback(
        (stop: IStop) => {
            setSelectedStopId(stop.id);
            setShowStop(true);
        },
        [setSelectedStopId],
    );

    useEffect(() => {
        const persistInitialStop = async () => {
            try {
                const initialStop = await getStop(String(selectedStopId));
                handleSelectStop(initialStop.data);
            } catch {
                dispatch(showBackendMessage(intl, "error", "fetching", Messages.stop));
            }
        };
        if (selectedStopId) {
            persistInitialStop();
        }
    }, [selectedStopId, dispatch, intl, handleSelectStop]);

    const selectedStop = useMemo(
        () => stops.find(s => s.id === selectedStopId),
        [stops, selectedStopId],
    );

    const getDestinationName = useCallback(
        (id: string) => (id ? destinations.find(s => s.id === id)?.name : "N/A"),
        [destinations],
    );

    const fetchStops = useCallback(async () => {
        try {
            setLoading(true);
            const res = await searchStops({
                ...searchParamsInit,
                filter: { voyageId: guid },
                order: ["eta asc"],
            });
            setStops(res.data.rows ?? []);
            const bookingFinished = res.data?.rows?.some(
                s => s.etd && getDateMoment(s.etd).toDate().getTime() < Date.now(),
            );
            setDisableShipInput(!changeVoyageAvailable || bookingFinished);
            const hasActiveStatus = res.data?.rows?.some(s => {
                return (
                    s.bookingStatus &&
                    ["REQUESTED", "CONFIRMED", "CHANGED", "REQUESTED_CHANGE"].includes(
                        s.bookingStatus,
                    )
                );
            });
            const hasBookingRequests = !!res.data?.rows?.some(s => !!s.bookingId);
            setHasBookingRequests(hasBookingRequests);
            setHasActiveStatus(hasActiveStatus);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.stops));
        } finally {
            setLoading(false);
        }
    }, [guid, changeVoyageAvailable, dispatch, intl]);

    const onStopCreate = (stop: IAddEditStop) => {
        setSelectedStopId(stop.id);
        setShowStop(true);
    };

    const fetchVoyage = useCallback(async () => {
        if (!guid) return;
        try {
            setLoading(true);
            const voyageRes = await getVoyage(guid);
            setVoyage(voyageRes.data);
            fetchStops();
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.voyage));
        } finally {
            setLoading(false);
        }
    }, [guid, fetchStops, dispatch, intl]);

    useEffect(() => {
        fetchVoyage();
    }, [fetchVoyage]);

    const getShips = useCallback(async () => {
        try {
            setLoading(true);
            const res = await searchShip({
                ...searchParamsInit,
                showDeleted: true,
            } as ISearchParams);
            setShips(res.data.rows ?? []);
        } catch {
            dispatch(showBackendMessage(intl, "error", "fetching", Messages.ships));
        } finally {
            setLoading(false);
        }
    }, [intl, dispatch]);

    useEffect(() => {
        getShips();
    }, [getShips]);

    const getBookingStatus = (status: string | null | undefined) => {
        switch (status) {
            case "REQUESTED":
                return `${intl.formatMessage(Messages.booking)} ${intl.formatMessage(
                    Messages.requested,
                )}`;
            case "REQUESTED_CHANGE":
                return `${intl.formatMessage(Messages.booking)} ${intl.formatMessage(
                    Messages.changeRequested,
                )}`;
            case "PENDING":
                return `${intl.formatMessage(Messages.booking)} ${intl.formatMessage(
                    Messages.pending,
                )}`;
            case "PROVISIONALLY_PLANNED":
                return `${intl.formatMessage(Messages.booking)} ${intl.formatMessage(
                    Messages.provisionallyPlanned,
                )}`;
            case "PROVISIONALLY_DECLINED":
                return `${intl.formatMessage(Messages.booking)} ${intl.formatMessage(
                    Messages.provisionallyDeclined,
                )}`;
            case "CHANGED":
                return intl.formatMessage(Messages.changed);

            case "CONFIRMED":
                return `${intl.formatMessage(Messages.booking)} ${intl.formatMessage(
                    Messages.confirmed,
                )}`;
            case "DECLINED":
                return `${intl.formatMessage(Messages.booking)} ${intl.formatMessage(
                    Messages.declined,
                )}`;
            case "CANCELLED":
                return `${intl.formatMessage(Messages.booking)} ${intl.formatMessage(
                    Messages.canceled,
                )}`;

            default:
                return "";
        }
    };

    const handleDiscardVoyage = async () => {
        if (!voyage) return;
        setLoading(true);
        try {
            await deleteVoyage(voyage.id);
            dispatch(showBackendMessage(intl, "success", "deleting", Messages.voyage));
        } catch (error) {
            dispatch(showBackendMessage(intl, "error", "deleting", Messages.voyage));
        } finally {
            setLoading(false);
            navigate("/manage-voyages");
        }
    };

    return (
        <RpisPage
            title={intl.formatMessage(Messages.planVoyage)}
            className="ld-plan-voyage-container"
        >
            <div className="plan-voyage-columns-container">
                <div className="voyage-container">
                    <div className="voyage-header">
                        <IconButton
                            onClick={() => navigate(`/manage-voyages`)}
                            className="arrow-icon"
                        >
                            <ArrowBackIcon fontSize="medium" />
                        </IconButton>
                        <h2>
                            {voyage
                                ? `${intl.formatMessage(Messages.voyage)} #${voyage?.id}`
                                : intl.formatMessage(Messages.addVoyage)}
                        </h2>
                        <IconButton
                            className="more-icon"
                            onClick={event => setMenuAnchorEl(event.currentTarget)}
                        >
                            <MoreVertIcon fontSize="medium" />
                        </IconButton>
                        <Menu
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl)}
                            onClose={() => setMenuAnchorEl(null)}
                        >
                            {!hasActiveStatus ? (
                                <MenuItem onClick={() => setShowDiscardVoyageModal(true)}>
                                    <span>Discard voyage</span>
                                </MenuItem>
                            ) : (
                                <Tooltip
                                    title={intl.formatMessage(Messages.activeRequestWarning)}
                                    arrow
                                >
                                    <span>
                                        <MenuItem disabled>
                                            <span>Discard voyage</span>
                                        </MenuItem>
                                    </span>
                                </Tooltip>
                            )}
                        </Menu>
                    </div>
                    <VoyageForm
                        selectedVoyage={voyage}
                        ships={ships}
                        hasBookingRequests={hasBookingRequests}
                        disableShipInput={disableShipInput}
                        addresses={addresses}
                    />

                    {guid && (
                        <>
                            <Divider />
                            <p>
                                {stops.length} {intl.formatMessage(Messages.stops)}
                            </p>
                            {loading ? (
                                <div>
                                    <CircularProgress />
                                </div>
                            ) : (
                                <>
                                    <Timeline className="timeline">
                                        {stops.map(stop => (
                                            <TimelineItem
                                                key={stop.id}
                                                className={classNames("timeline-item", {
                                                    background:
                                                        stop.id === selectedStop?.id && showStop,
                                                })}
                                                onClick={() => handleSelectStop(stop)}
                                            >
                                                <TimelineOppositeContent
                                                    className="timeline-opposite-content"
                                                    align="right"
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    <div>
                                                        {getDateText(
                                                            stop.eta,
                                                            DateFormat.CLIENT_DATE_TIME,
                                                            timeZones.get(stop.destinationId),
                                                        )}
                                                    </div>

                                                    <div>
                                                        {getDateText(
                                                            stop.etd,
                                                            DateFormat.CLIENT_DATE_TIME,
                                                            timeZones.get(stop.destinationId),
                                                        )}
                                                    </div>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot>
                                                        <AnchorIcon
                                                            color={
                                                                getStatusChip(stop.bookingStatus)
                                                                    ?.color
                                                            }
                                                        />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent className="timeline-content">
                                                    <h4>
                                                        {getDestinationName(stop.destinationId)}
                                                    </h4>
                                                    <p>{getBookingStatus(stop.bookingStatus)}</p>
                                                    <BerthingsDockName
                                                        berthingDockGroups={stop.berthings}
                                                    />
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))}
                                    </Timeline>

                                    <Button
                                        id="submit"
                                        variant="contained"
                                        onClick={() => {
                                            setSelectedStopId(null);
                                            setShowStop(true);
                                        }}
                                    >
                                        {intl.formatMessage(Messages.addStop)}
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </div>

                <div className="stop-container">
                    {showStop && (
                        <StopForm
                            selectedStop={selectedStop}
                            voyageId={guid}
                            onApiOperationEnd={fetchStops}
                            onApiCreateSuccess={s => onStopCreate(s)}
                            onApiUpdateSuccess={() => setShowStop(true)}
                            onApiDeleteSuccess={() => setShowStop(false)}
                        />
                    )}
                </div>
                <div className="chat-container">
                    <Chat bookingId={selectedStop?.bookingId} />
                </div>
            </div>
            <Dialog open={showDiscardVoyageModal} onClose={() => setShowDiscardVoyageModal(false)}>
                <DialogTitle>{intl.formatMessage(Messages.voyageDiscardConfirmation)}</DialogTitle>
                <DialogActions>
                    <Button variant="contained" onClick={() => handleDiscardVoyage()} color="error">
                        {intl.formatMessage(Messages.discard)}
                    </Button>
                    <Button
                        onClick={() => setShowDiscardVoyageModal(false)}
                        variant="outlined"
                        color="primary"
                    >
                        {intl.formatMessage(Messages.cancel)}
                    </Button>
                </DialogActions>
            </Dialog>
        </RpisPage>
    );
};

export default PlanVoyage;
